import React, { useState } from "react";
import pageImage from "images/HomepageLogo.png";
import { DocPageLayout, PublishedNotebook, SEO } from "components";

const PublishedNotebookPage = ({ location }) => {
  const title = "ECL Published Notebook";
  const description = "Emerald Cloud Lab Notebook Browser";

  const [identifier, setIdentifier] = useState("");

  const searchParams = new URLSearchParams(location.search);
  const queryParamsId = searchParams.get("id");

  if (queryParamsId !== null && queryParamsId !== identifier) {
    setIdentifier(queryParamsId);
  }

  return (
    <DocPageLayout headerText={"Publish"}>
      <SEO
        image={pageImage}
        title={title}
        uri={location.pathname}
        description={description}
      />
      <PublishedNotebook identifier={identifier} />
    </DocPageLayout>
  );
};

export default PublishedNotebookPage;
